import { type Article } from "@vgno/article";
import { TrackerStore } from "@vgno/pulse";
import { getUTMParams } from "@vgno/utils";

import { createIntersectionObserver, createTrackElement } from "./TrackElement";
import initTrackPageLeave from "./initTrackPageLeave";
import initTrackPageView from "./initTrackPageView";
import initUtmAppender from "./initUtmAppender";
import trackElementClick from "./trackElementClick";
import trackElementView from "./trackElementView";
import { getDataset, getExperiments, getMetrics } from "./utils";

const tracker = TrackerStore.get();

const utmTags = getUTMParams(window.location.toString(), true);
const features = JSON.parse(
  document.getElementById("features")?.textContent || "[]",
);
const articleData: Article["pulseData"] = JSON.parse(
  document.getElementById("article-pulse-data")?.textContent || "",
);

const isDev = import.meta.env.DEV;
const isFeature = window.location.host.includes("feature.sk8s.vg.no");

const init = async () => {
  const release = getDataset(
    "[data-release]",
    document.documentElement,
    "release",
  );

  if (features) tracker.addExperiments(features);
  const experiments = await getExperiments();
  if (experiments) tracker.addExperiments(experiments);

  // This function call is here to add some global data to the tracker
  tracker.updateBaseEvent({
    deployTag: release || "vgno-dev",
    deployStage: isFeature ? "pre" : isDev ? "dev" : "pro",
    metrics: getMetrics(),
    // location: async () => {
    // Enable if we want to track location. Need privacy approval
    //   if (!navigator.permissions) return;

    //   const result = await navigator.permissions.query({ name: "geolocation" });

    //   if (result.state !== "granted") return;
    //   if (!navigator.geolocation) return;

    //   const position = await new Promise<GeolocationPosition>(
    //     (resolve, reject) =>
    //       navigator.geolocation.getCurrentPosition(resolve, reject, {
    //         timeout: 1000,
    //       }),
    //   );

    //   return position.coords.toJSON();
    // },
  });

  // We have a <track-element> custom element that we use to track views
  // of dynamically loaded elements.
  const TrackElement = createTrackElement((event) =>
    trackElementView(event, tracker),
  );

  if (!customElements.get("track-element")) {
    customElements.define("track-element", TrackElement);
  }

  // Here we initialize the tracking of the page view
  initTrackPageView(articleData, utmTags, tracker);

  // This function is used to append utm tags to links that are clicked
  initUtmAppender(trackElementClick, tracker);

  // Some events are available on dom ready, so we append the
  // view listeners to them directly instead if the above <track-element> wrapper
  const initTrackImpression = (): void => {
    const trackableElements = document.querySelectorAll<HTMLElement>(
      "[data-track-impression]:not(track-element)",
    );

    trackableElements.forEach((element) => {
      const observer = createIntersectionObserver(
        (elm) => trackElementView(elm, tracker),
        element,
      );
      observer.observe(element);
    });
  };

  // We need to wait for the dom to be ready before we can
  // start tracking impressions and page leave
  if (/complete|interactive/.test(document.readyState)) {
    initTrackImpression();
    initTrackPageLeave(tracker);
  } else {
    document.addEventListener("DOMContentLoaded", () => {
      initTrackImpression();
      initTrackPageLeave(tracker);
    });
  }
};

export default init;
