type TrackImpression = (element: HTMLElement) => unknown;

/**
 * For each element, keep:
 *   wasTracked      - Whether we've fired trackImpression yet.
 *   accumulatedTime - Total ms visible across all sessions so far.
 *   lastStartTime   - The performance.now() at which the current
 *                     visibility session began.
 *   intervalId      - The active setInterval ID (if any).
 */
interface TrackingState {
  wasTracked: boolean;
  accumulatedTime: number;
  lastStartTime: number | null;
  intervalId: number | null;
}

const trackingMap = new WeakMap<HTMLElement, TrackingState>();

export const createIntersectionObserver = (
  trackImpression: TrackImpression,
  trackElement: HTMLElement,
) =>
  new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const elem = entry.target as HTMLElement;
        let state = trackingMap.get(elem);

        // If we've never tracked this element before, initialize its state
        if (!state) {
          state = {
            wasTracked: false,
            accumulatedTime: 0,
            lastStartTime: null,
            intervalId: null,
          };
          trackingMap.set(elem, state);
        }

        // element is at least 50% visible
        if (entry.isIntersecting) {
          // 1) Fire trackImpression ONLY if we haven't before
          if (!state.wasTracked) {
            trackImpression(elem);
            state.wasTracked = true;
          }

          // 2) Start or resume counting
          //    If there's no active interval, set one up
          if (state.intervalId == null) {
            // Note the time we *re-entered* the viewport
            state.lastStartTime = performance.now();

            // Update the DOM attribute ~ every 500ms
            const intervalId = window.setInterval(() => {
              // If still visible, compute how many ms have passed
              if (state.lastStartTime != null) {
                const elapsed = performance.now() - state.lastStartTime;
                const total = state.accumulatedTime + elapsed;
                trackElement.setAttribute(
                  "data-track-duration",
                  String(Math.floor(total)),
                );
              }
            }, 500);

            state.intervalId = intervalId;
          }
        } else {
          // element is below 50% visible
          // => Stop counting if we were counting
          if (state.intervalId != null) {
            clearInterval(state.intervalId);
            state.intervalId = null;

            // Update accumulatedTime with the time spent in this last session
            if (state.lastStartTime != null) {
              const elapsed = performance.now() - state.lastStartTime;
              state.accumulatedTime += elapsed;
            }

            state.lastStartTime = null;
          }
        }
      });
    },
    {
      rootMargin: "0px",
      threshold: 0.5,
    },
  );

/**
 * Helper to get computed styles (works with older browsers, too).
 */
const getStyle = (elem: HTMLElement, prop: string) =>
  elem.computedStyleMap
    ? elem.computedStyleMap().get(prop)?.toString()
    : getComputedStyle(elem).getPropertyValue(prop);

/**
 * Create a custom <track-element>, which:
 *   - Observes the child that isn't `display: contents`
 *   - Attaches IntersectionObserver so we track its overall visibility time
 *   - Stores the ms in `data-track-duration` on the <track-element> itself
 */
export function createTrackElement(
  trackElementView: TrackImpression,
): typeof HTMLElement {
  class TrackElement extends HTMLElement {
    private observer = createIntersectionObserver(trackElementView, this);

    constructor() {
      super();
    }

    connectedCallback() {
      // Start observing the "real" child
      this.observer.observe(this.trackElement());
    }

    disconnectedCallback() {
      // If the element is removed from DOM, unobserve it
      this.observer.unobserve(this.trackElement());
    }

    /**
     * If the child is `display: contents`, skip until we find a "real" element
     */
    trackElement(elem: Element | null = this.firstElementChild): HTMLElement {
      if (!elem || !(elem instanceof HTMLElement)) {
        throw new Error("TrackElement must have a child element");
      }

      // If the child is `display: contents`, keep digging
      if (elem.computedStyleMap?.().get("display")?.toString() === "contents") {
        return this.trackElement(elem.firstElementChild);
      }
      if (getStyle(elem, "display") === "contents") {
        return this.trackElement(elem.firstElementChild);
      }

      return elem;
    }
  }

  return TrackElement;
}
