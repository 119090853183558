import { toSlug } from "@vgno/utils";

import { getDataset, getValidAccessLevel, getValidTargetType } from "./utils";

import type {
  ClickUIElementInputObject,
  NewsMediaPulseTracker,
} from "@schibsted/pulse-news-media";

const trackElementClick = (
  eventTarget: HTMLElement,
  tracker: NewsMediaPulseTracker,
) => {
  const name = getDataset("[data-track-name]", eventTarget, "trackName");
  const id =
    getDataset("[data-track-id]", eventTarget, "trackId") || toSlug(name || "");

  if (!id) return;

  const target = getDataset("[data-track-target]", eventTarget, "trackTarget");
  const targetType = getDataset(
    "[data-track-target-type]",
    eventTarget,
    "trackTargetType",
  );
  const clickIntent = getDataset(
    "[data-track-click-intent]",
    eventTarget,
    "trackClickIntent",
  );
  const duration = getDataset(
    "[data-track-duration]",
    eventTarget,
    "trackDuration",
    true,
  );
  const curateContext = getDataset(
    "[data-track-curate-context]",
    eventTarget,
    "trackCurateContext",
  );
  const positionInBundle = getDataset(
    "[data-track-bundle-position]",
    eventTarget,
    "trackBundlePosition",
    true,
  );
  const primaryPosition = getDataset(
    "[data-track-primary-position]",
    eventTarget,
    "trackPrimaryPosition",
    true,
  );
  const secondaryPosition = getDataset(
    "[data-track-secondary-position]",
    eventTarget,
    "trackSecondaryPosition",
    true,
  );
  const positionName = getDataset(
    "[data-track-position-name]",
    eventTarget,
    "trackPositionName",
  );
  const elementSize = getDataset(
    "[data-track-element-size]",
    eventTarget,
    "trackElementSize",
  );
  const imageUrl = getDataset(
    "[data-track-image-url]",
    eventTarget,
    "trackImageUrl",
  );
  const layoutName = getDataset(
    "[data-track-layout-name]",
    eventTarget,
    "trackLayoutName",
  );

  const elementType = getDataset(
    "[data-track-element-type]",
    eventTarget,
    "trackElementType",
  );
  const targetNewsroom = getDataset(
    "[data-track-target-newsroom]",
    eventTarget,
    "trackTargetNewsroom",
  );
  const accessLevel = getDataset(
    "[data-track-access-level]",
    eventTarget,
    "trackAccessLevel",
  );
  const accessLevelName = getDataset(
    "[data-track-access-level-name]",
    eventTarget,
    "trackAccessLevelName",
  );

  const layout: ClickUIElementInputObject["layout"] = {
    elementSize,
    imageUrl,
    name: layoutName,
  };

  return tracker.trackClickUIElement({
    target: {
      accessLevel: getValidAccessLevel(accessLevel),
      accessLevelName: accessLevelName || undefined,
      id: id,
      name: name || "unknown",
      newsroom: targetNewsroom,
      type: getValidTargetType(targetType),
      url: target || document.location.href,
    },
    object: {
      duration: duration,
      elementType: elementType || "Element",
      id,
      intent: clickIntent,
      ...(layout?.name && { layout }),
      name: name || "unknown",
      position: positionName,
      size: elementSize,
      ...(primaryPosition && {
        placement: {
          curateContext,
          positionInBundle,
          primaryPosition,
          secondaryPosition,
        },
      }),
    },
  });
};

export default trackElementClick;
