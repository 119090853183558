import { type NewsMediaPulseTracker } from "@schibsted/pulse-news-media";
import { getUTMParams } from "@vgno/utils";

import { getDataset, getParentId, isLinkfusionUrl } from "./utils";

const initUtmAppender = (
  trackElementClick: (
    eventTarget: HTMLElement,
    tracker: NewsMediaPulseTracker,
  ) => void,
  tracker: NewsMediaPulseTracker,
) => {
  const getCanonicalUrl = () => {
    const canonical = document.querySelector("link[rel='canonical']");
    return canonical ? canonical.getAttribute("href") : "";
  };
  const appendUtm = (eventTarget: HTMLElement): void => {
    const link = eventTarget.closest("a") as HTMLAnchorElement;

    if (!link) return;

    if (!/^((?!sms:|tel:|mailto:|javascript:|#).)*$/.test(link.href)) return;

    const url = new URL(link.href);
    let shouldUpdateUrl = false;

    const utm_medium = getDataset(
      "[data-track-element-type]",
      eventTarget,
      "trackElementType",
      false,
    );
    const utm_content = getDataset(
      "[data-track-id]",
      eventTarget,
      "trackId",
      false,
    );
    const parentId = getParentId({
      rawId: document.documentElement.dataset.id || "unknown",
      type: document.documentElement.dataset.pageType || "unknown",
      tracker: tracker,
    });

    if (parentId && !url.searchParams.has("utm_source")) {
      url.searchParams.append("utm_source", parentId);
      shouldUpdateUrl = true;
    }
    if (utm_medium && !url.searchParams.has("utm_medium")) {
      url.searchParams.append("utm_medium", utm_medium);
      shouldUpdateUrl = true;
    }
    if (utm_content && !url.searchParams.has("utm_content")) {
      url.searchParams.append("utm_content", utm_content);
      shouldUpdateUrl = true;
    }

    if (isLinkfusionUrl(url)) {
      const utmTags = getUTMParams(window.location.toString(), true);
      for (const [key, value] of Object.entries(utmTags)) {
        url.searchParams.set(`utm_${key}`, value as string);
      }
      const canonicalUrl = getCanonicalUrl();
      if (canonicalUrl) {
        url.searchParams.set("r0", canonicalUrl);
      }
      const referrer = utmTags.url || document.referrer;
      url.searchParams.set("traffic_source", referrer);
      shouldUpdateUrl = true;
    }

    if (shouldUpdateUrl) {
      link.href = url.toString();
    }
  };

  const handleEvent = (event: MouseEvent, isMouseDown = false) => {
    const eventTarget = event.target as HTMLElement;

    if (eventTarget.closest("a") && !eventTarget.closest("[data-track-no-utm]"))
      appendUtm(eventTarget);

    if (isMouseDown) return;

    const closest = eventTarget.closest(
      "a, button, input, summary, label, [data-track-click]",
    );

    if (closest) trackElementClick(eventTarget, tracker);
  };

  document.addEventListener("click", handleEvent);
  document.addEventListener("mousedown", (event) => handleEvent(event, true));
  document.addEventListener("auxclick", (event) => {
    if (event.button !== 1) return;
    handleEvent(event);
  });
};

export default initUtmAppender;
